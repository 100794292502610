import React from 'react';

export default function IndexSectionLogoCloudsGrayMix13() {
    return (
        <React.Fragment>
            <>
                <section className="wave-top wave-bottom">
  <div className="wave wave-top w-full text-coolGray-50">
    <svg viewBox="0 0 1440 116" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1440 64.5909H1090.08C833.336 64.5909 580.229 -7.62939e-06 360 -7.62939e-06C139.771 -7.62939e-06 0 64.5909 0 64.5909V116H1440V64.5909Z" fill="currentColor" />
    </svg>
  </div>
  <div className="py-16 md:py-24 bg-coolGray-50" style={{backgroundImage: 'url("flex-ui-assets/elements/pattern-light1.svg")', backgroundPosition: 'center'}}>
    <div className="container px-4 mx-auto">
      <div className="flex flex-wrap items-center -mx-4">
        <div className="w-full lg:w-1/2 px-4 mb-10">
          <div className="max-w-md">
            <span className="inline-block py-px px-2 mb-4 text-xs leading-5 text-green-500 bg-green-100 font-medium uppercase rounded-9xl">Our Clients</span>
            <h3 className="mb-8 text-4xl md:text-5xl text-coolGray-900 font-bold tracking-tighter">Trusted by the top companies in this industry</h3>
            <p className="text-lg md:text-xl text-coolGray-500 font-medium">The only SaaS business platform that combines CRM, marketing automation &amp; commerce.</p>
          </div>
        </div>
        <div className="w-full lg:w-1/2 px-4">
          <div className="flex flex-wrap justify-center -mx-4">
            <div className="w-full md:w-1/2 px-4 mb-8">
              <div className="flex items-center h-32 md:h-36 px-4 md:px-8 rounded-md bg-white shadow-md">
                <img className="mx-auto" src="https://static.shuffle.dev/uploads/files/b8/b863891aa99406bca42fc41ab70471d0c7b2763e/crescent-foods-removebg-preview.png" alt />
              </div>
            </div>
            <div className="w-full md:w-1/2 px-4 mb-8 lg:mb-0">
              <div className="flex items-center h-32 md:h-36 px-4 md:px-8 rounded-md bg-white shadow-md">
                <img className="mx-auto h-32" src="https://static.shuffle.dev/uploads/files/b8/b863891aa99406bca42fc41ab70471d0c7b2763e/farrelcalhoun-removebg-preview.png" alt />
              </div>
            </div>
            <div className="w-full md:w-1/2 px-4 mb-8 lg:mb-0">
              <div className="flex items-center h-32 md:h-36 px-4 md:px-8 rounded-md bg-white shadow-md">
                <img className="mx-auto" src="https://static.shuffle.dev/uploads/files/b8/b863891aa99406bca42fc41ab70471d0c7b2763e/premierproduce-removebg-preview.png" alt />
              </div>
            </div>
            <div className="w-full md:w-1/2 px-4 mb-8 md:mb-0">
              <div className="flex items-center h-32 md:h-36 px-4 md:px-8 rounded-md bg-white shadow-md">
                <img className="mx-auto" src="https://static.shuffle.dev/uploads/files/b8/b863891aa99406bca42fc41ab70471d0c7b2763e/osage-removebg-preview.png" alt />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="wave wave-bottom w-full text-coolGray-50">
    <svg viewBox="0 0 1440 116" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 51.4091H349.922C606.664 51.4091 859.771 116 1080 116C1300.23 116 1440 51.4091 1440 51.4091V0H0V51.4091Z" fill="currentColor" />
    </svg>
  </div>
</section>


            </>
        </React.Fragment>
    );
}


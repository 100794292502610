import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import IndexSectionHeadersWhitePattern2 from '../components/headers-white-pattern/IndexSectionHeadersWhitePattern2';
import IndexSectionContactsDarkMix8 from '../components/contacts-dark-mix/IndexSectionContactsDarkMix8';
import IndexSectionFeaturesWhitePattern11 from '../components/features-white-pattern/IndexSectionFeaturesWhitePattern11';
import IndexSectionLogoCloudsGrayMix13 from '../components/logo-clouds-gray-mix/IndexSectionLogoCloudsGrayMix13';
import IndexSectionFootersWhitePattern12 from '../components/footers-white-pattern/IndexSectionFootersWhitePattern12';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <IndexSectionHeadersWhitePattern2 />
      <IndexSectionContactsDarkMix8 />
      <IndexSectionFeaturesWhitePattern11 />
      <IndexSectionLogoCloudsGrayMix13 />
      <IndexSectionFootersWhitePattern12 />
    </React.Fragment>
  );
}


import React from "react";

export default function IndexSectionHeadersWhitePattern2() {
  return (
    <React.Fragment>
      <>
        <section
          className="relative bg-white overflow-hidden "
          style={{
            backgroundImage: 'url("flex-ui-assets/elements/pattern-white.svg")',
            backgroundPosition: "center",
          }}
        >
          <div className="py-20 md:py-28">
            <div className="container px-4 mx-auto">
              <div className="flex flex-wrap-reverse xl:items-center -mx-4">
                <div className="w-full md:w-1/2 px-4 mb-16 md:mb-0">
                  <span className="inline-block py-px px-2 mb-4 text-xs leading-5 text-white bg-green-500 uppercase rounded-9xl">
                    Highest
                  </span>
                  <h1 className="mb-6 text-3xl md:text-5xl lg:text-6xl leading-tight font-bold tracking-tight">
                    DARWIN LOGISTICS
                  </h1>
                  <p className="mb-8 text-lg md:text-xl text-coolGray-500 font-medium">
                    Welcome to Darwin Logistics, your premier freight brokerage
                    partner. At Darwin Logistics, we specialize in providing
                    seamless and efficient transportation solutions tailored to
                    meet the unique needs of your business. Our expertise in
                    freight brokerage ensures that your shipments are handled
                    with the utmost care and delivered on time, every time.
                  </p>
                  <div className="flex flex-wrap">
                    <div className="w-full md:w-auto py-1 md:py-0 md:mr-4 lg:w-80">
                      <a
                        className="inline-block py-5 px-7 w-full text-base md:text-lg leading-4 text-green-50 font-medium text-center bg-green-500 hover:bg-green-600 focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 border border-green-500 rounded-md shadow-sm"
                        href="#"
                      >
                        Call Us
                      </a>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-1/2 px-4">
                  <div className="relative mx-auto md:mr-0 max-w-max">
                    <img
                      className="absolute z-10 -left-14 -top-12 md:w-auto w-28"
                      src="flex-ui-assets/elements/wave-green.svg"
                      alt
                    />
                    <img
                      className="absolute z-10 -right-7 -bottom-8 w-28 md:w-auto"
                      src="flex-ui-assets/elements/dots3-blue.svg"
                      alt
                    />
                    <img
                      className="relative rounded-7xl"
                      src="https://static.shuffle.dev/uploads/files/b8/b863891aa99406bca42fc41ab70471d0c7b2763e/DARWIN-logo-1.jpg"
                      alt
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}

import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import OpeningsSectionNavigationsWhite3 from '../components/navigations-white/OpeningsSectionNavigationsWhite3';
import OpeningsSectionCareersWhitePattern1 from '../components/careers-white-pattern/OpeningsSectionCareersWhitePattern1';
import OpeningsSectionFootersWhitePattern2 from '../components/footers-white-pattern/OpeningsSectionFootersWhitePattern2';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Openings() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <OpeningsSectionCareersWhitePattern1 />
      <OpeningsSectionFootersWhitePattern2 />
    </React.Fragment>
  );
}


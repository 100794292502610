import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ApplicationSectionNavigationsWhite3 from '../components/navigations-white/ApplicationSectionNavigationsWhite3';
import ApplicationSectionCtaWhitePattern1 from '../components/cta-white-pattern/ApplicationSectionCtaWhitePattern1';
import ApplicationSectionFootersWhitePattern2 from '../components/footers-white-pattern/ApplicationSectionFootersWhitePattern2';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Application() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <ApplicationSectionCtaWhitePattern1 />
      
      <ApplicationSectionFootersWhitePattern2 />
    </React.Fragment>
  );
}


import React from "react";

export default function ApplicationSectionCtaWhitePattern1() {
  return (
    <React.Fragment>
      <>
        <section
          className="relative py-24 md:pb-32 bg-white overflow-hidden"
          style={{
            backgroundImage: 'url("flex-ui-assets/elements/pattern-white.svg")',
            backgroundPosition: "center",
          }}
        >
          <div className="relative container px-4 mx-auto">
            <div className="xl:max-w-4xl mb-18 md:mb-16 mx-auto text-center">
              
              <iframe
            title="Broker Application Form"
            src="https://form.jotform.com/241775086238161?page=application.html&screen=main&parentURL=https%3A%2F%2Fshuffle.dev%2Fpreview%2Fb863891aa99406bca42fc41ab70471d0c7b2763e%3Fpage%3Dapplication.html%26screen%3Dmain&jsForm=true"
            allow="geolocation; microphone; camera; fullscreen"
            name="241775086238161"
            id="241775086238161"
            scrolling="no"
            style={{
              width: "10px",
              minWidth: "100%",
              display: "block",
              overflow: "hidden",
              height: "1432px",
              border: "none"
            }}
          ></iframe>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}

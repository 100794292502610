import React from 'react';

export default function ContactSectionFootersWhitePattern2() {
    return (
        <React.Fragment>
            <>
                <div>
  <section className="bg-white" style={{backgroundImage: 'url("flex-ui-assets/elements/pattern-white.svg")', backgroundPosition: 'center'}}>
    <div className="container px-4 mx-auto">
      <div className="pt-24 pb-11 mx-auto max-w-4xl">
        <a className="block md:mx-auto mb-5 max-w-max" href="/">
          <img className="lg:h-28 h-20" src="https://static.shuffle.dev/uploads/files/b8/b863891aa99406bca42fc41ab70471d0c7b2763e/DARWIN-logo-with-text.png" alt />
        </a>
        <div className="flex flex-wrap justify-center -mx-3 lg:-mx-6">
          <div className="w-full md:w-auto p-3 md:px-6"><a className="inline-block text-lg md:text-xl text-coolGray-500 hover:text-coolGray-600 font-medium" href="/">Home</a></div>
          <div className="w-full md:w-auto p-3 md:px-6"><a className="inline-block text-lg md:text-xl text-coolGray-500 hover:text-coolGray-600 font-medium" href="/contact">Contact</a></div>
          <div className="w-full md:w-auto p-3 md:px-6"><a className="inline-block text-lg md:text-xl text-coolGray-500 hover:text-coolGray-600 font-medium" href="/openings">Career</a></div>
          <div className="w-full md:w-auto p-3 md:px-6"><a className="inline-block text-lg md:text-xl text-coolGray-500 hover:text-coolGray-600 font-medium" href="/application">Application</a></div>
        </div>
      </div>
    </div>
    <div className="border-b border-coolGray-100" />
    <div className="container px-4 mx-auto">
      <p className="py-10 md:pb-20 text-lg md:text-xl text-coolGray-400 font-medium text-center">© 2024 DARWIN LOGISTICS. All rights reserved.</p>
    </div>
  </section>
</div>


            </>
        </React.Fragment>
    );
}


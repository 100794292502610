import React from 'react';
import {
  Routes,
  Route
} from 'react-router-dom';

import IndexPage from './pages/Index.js';
import ApplicationPage from './pages/Application.js';
import ContactPage from './pages/Contact.js';
import OpeningsPage from './pages/Openings.js';
import Navbar from './components/navbar.jsx';

function App() {
  return (
    <>
    <Navbar />
    <Routes>
      <Route path="/" element={<IndexPage />} />
         
      <Route path="/application" element={<ApplicationPage />} />
         
      <Route path="/contact" element={<ContactPage />} />
         
      <Route path="/openings" element={<OpeningsPage />} />
    </Routes>
    </>
  );
}

export default App;
